<template>
  <div class="DataCenter">
    <div class="DataCenter_body">
        <div class="title" style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 5px;">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-left: 20px;">
      <el-breadcrumb-item :to="{ path: '/report' }">工作汇报</el-breadcrumb-item>
      <el-breadcrumb-item>已收集问卷信息</el-breadcrumb-item>
    </el-breadcrumb>

      <el-button type="primary" style="margin-right: 20px;" @click="$router.go(-1)">返回</el-button>
    </div>
      <div class="DataCenter_middle content">
        <el-table
          border
          :data="tableData"
          height="96%"
          style="width: 100%; height: 630px"
          :header-cell-style="{ background: 'rgb(240, 247, 253)' }"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            v-if="admin == '00000000-0000-0000-0000-000000000000'"
            prop="OrganizationName"
            label="组织名称"
            min-width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Frq"
            :sortable="true"
            :sort-method="sortByFrq"
            label="访视次数"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.Frq == 0 ? "基线" : scope.row.Frq }}</p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="QuestionnaireName"
            label="问卷名称"
            :sortable="true"
            :sort-method="sortByQuestionnaireName"
            width="480"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="StatusName"
            label="问卷状态"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="TargetName"
            :sortable="true"
            :sort-method="sortByName"
            label="患者姓名"
          >
          </el-table-column>
          <el-table-column align="center" prop="TargetTel" label="患者电话">
          </el-table-column>
          <el-table-column
            label="填写时间"
            :sortable="true"
            :sort-method="sortByCreateTime"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.EndTime ? scope.row.EndTime : scope.row.BeginTime }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-link @click="ViewQuestionnaire(scope.row)" :underline="false"
                ><i class="el-icon-zoom-in"></i>查看/修改</el-link
              >
              <!-- <el-link @click="ModifyQuestionnaire(scope.row)"><i class="el-icon-zoom-in"></i>修改</el-link> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.rows"
        @pagination="CollectedQuestionnaire"
      ></Pagination>
    </div>
    <div>
      <el-dialog :visible.sync="dialogVisible" fullscreen>
        <div>
          <iframe
            v-if="Url != ''"
            :src="Url"
            frameborder="0"
            width="100%"
            height="880px"
            ref="myFrame"
          ></iframe>
          <div
            v-if="dataRow && dataRow.VoiceFile"
            style="position: absolute; top: 10%; right: 10%"
          >
            <audio :src="dataRow.VoiceFile" controls preload="auto"></audio>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagination from "../../components/Pagination/index.vue";
import {local} from "../../utils/storage";
import WorkReport from "../../api/WorkReport";
export default {
  data() {
    return {
      admin: local.get("UserId"),
      tableData: [],
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      //存放当前行的数据
      dataRow:{},
      dialogVisible:false,
      Url:''
    };
  },
  components: {
    Pagination,
  },
  created() {
    this.CollectedQuestionnaire();
  },
  methods: {
    //通过文字进行排序
    sortByQuestionnaireName(obj1, obj2) {
      if (
        [obj1.QuestionnaireName, obj2.QuestionnaireName][0] ==
        [obj1.QuestionnaireName, obj2.QuestionnaireName].sort((a, b) =>
          a.localeCompare(b, "zh", { sensitivity: "accent" })
        )[0]
      ) {
        return -1;
      } else {
        return 1;
      }
    },
    //数字文字排序
    sortByFrq(obj1, obj2) {
      if (
        [obj1.Frq, obj2.Frq][0] ==
        [obj1.Frq, obj2.Frq].sort((a, b) => {
          var c = isFinite(a),
            d = isFinite(b);
          return (
            (c != d && d - c) ||
            (c && d
              ? a - b
              : a.localeCompare(b, "zh", { sensitivity: "accent" }))
          );
        })[0]
      ) {
        return -1;
      } else {
        return 1;
      }
    },
    sortByName(obj1, obj2) {
      if (
        [obj1.TargetName, obj2.TargetName][0] ==
        [obj1.TargetName, obj2.TargetName].sort((a, b) =>
          a.localeCompare(b, "zh", { sensitivity: "accent" })
        )[0]
      ) {
        return -1;
      } else {
        return 1;
      }
    },
    sortByCreateTime(obj1, obj2) {
      console.log(obj1, obj2);
    },
    //查看问卷
    ViewQuestionnaire(row) {
      this.Url = "";
      this.dataRow = row;
      this.dialogVisible = true;
      this.Url = `${window.global_config.BASE_URL1}/#/ViewModification?cqId=${
        row.CollectedQuestionnaireId
      }&r=${Math.random() * 10}`;
      this.Url = "about:blank";
      setTimeout(() => {
        this.Url = `${window.global_config.BASE_URL1}/#/ViewModification?cqId=${
          row.CollectedQuestionnaireId
        }&r=${Math.random() * 10}`;
      }, 300);
    },
    async CollectedQuestionnaire(){
        const params = {
            ...this.listQuery,
            ...this.$route.query
        };
        const { data } = await WorkReport.GetPatientList(params);
        this.tableData = data.rows;
        this.total = data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.DataCenter_head {
  ::v-deep .el-input {
    width: 300px;
  }
}
::v-deep .el-tabs__item {
  font-size: 18px;
}
::v-deep .el-dialog .el-dialog__header {
  margin-bottom: 0;
}
::v-deep .el-dialog__headerbtn {
  top: 4px;
  font-size: 22px;
}
::v-deep .el-dialog__body {
  padding: 0;
}
// ::v-deep .el-input__inner{
//   height: 30px;
//   line-height: 30px;
// }
// ::v-deep .el-input__icon{
//   line-height: 30px;
// }
.DataCenter {
  width: 100%;
  height: 98%;
  min-width: 1440px;
  padding: 20px;
  box-sizing: border-box;
  // border-radius: 10px;
  overflow: hidden;
  .DataCenter_body {
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .DataCenter_head {
      margin-bottom: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    .DataCenter_middle {
      // height: 630px;
      // padding: 20px;
      box-sizing: border-box;
      margin-bottom: 10px;
      flex: 1;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .DataCenter {
    min-width: 0 !important;

    .DataCenter_head {
      ::v-deep .el-input--suffix {
        width: 140px;
      }
    }

    .DataCenter_middle {
      margin-bottom: 0 !important;
    }
  }
}
</style>